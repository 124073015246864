import classnames from 'classnames';
import Link from 'next/link';
import React from 'react';
import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  TRANSPARENT = 'transparent',
  OUTLINED = 'outline',
  LINK = 'link',
}

type ButtonProps = {
  className?: string,
  children: React.ReactNode,
  variant?: ButtonVariants,
  icon?: React.ReactNode,
  iconPosition?: 'left' | 'right',
  iconSize?: {
    width: number,
    height: number,
  }
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  size?: 'normal' | 'medium' | 'big',
  link?: string,
  disabled?: boolean,
  type?: 'button' | 'submit',
  tabIndex?: number,
};

const Button = ({
  className,
  children,
  variant,
  icon,
  iconPosition = 'right',
  iconSize,
  onClick,
  size = 'normal',
  link = undefined,
  disabled = false,
  type = 'button',
  tabIndex,
}: ButtonProps) => (link ? (
  <Link
    href={link}
    type='button'
    className={classnames(styles.button, className, {
      [styles.primary]: variant === ButtonVariants.PRIMARY,
      [styles.transparent]: variant === ButtonVariants.TRANSPARENT,
      [styles.outlined]: variant === ButtonVariants.OUTLINED,
      [styles.link]: variant === ButtonVariants.LINK,
      [styles.withIcon]: icon,
      [styles.withIcon_left]: iconPosition === 'left',
      [styles.big]: size === 'big',
      [styles.medium]: size === 'medium',
      [styles.disabled]: disabled,
    })}
  >
    {children}
    {icon && (
    <div
      className={styles.icon}
      style={{
        width: iconSize?.width,
        height: iconSize?.height,
      }}
    >
      {icon}
    </div>
    )}
  </Link>
) : (
  <button // eslint-disable-next-line react/button-has-type
    type={type}
    tabIndex={tabIndex}
    className={classnames(styles.button, className, {
      [styles.primary]: variant === ButtonVariants.PRIMARY,
      [styles.transparent]: variant === ButtonVariants.TRANSPARENT,
      [styles.outlined]: variant === ButtonVariants.OUTLINED,
      [styles.withIcon]: icon,
      [styles.withIcon_left]: iconPosition === 'left',
      [styles.big]: size === 'big',
      [styles.medium]: size === 'medium',
    })}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
    {icon && (
      <div
        className={styles.icon}
        style={{
          width: iconSize?.width,
          height: iconSize?.height,
        }}
      >
        {icon}
      </div>
    )}
  </button>
));

export default Button;
